import React from 'react'
import SuccessMembershipMessage from '../components/SuccessMembershipMessage'
import Layout from '../components/Layout'

export default () => (
  <Layout themeType="dark" currentPage={'success-membership-message'}>
    <div>
      <SuccessMembershipMessage />
    </div>
  </Layout>
)