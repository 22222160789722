import React from 'react'
import { PaymentConfirmationSection } from './style'
import PurchaseLayout from '../Layout/Purchase'
import Check from '../../assets/images/general/check.jpg'

export default function SuccessMembershipMessage() {
  return (
    <PurchaseLayout title="Envío exitoso">
      <PaymentConfirmationSection>
        <div className="purchases-container box">
          <div>
            <img src={Check} />
          </div>
          <div className="success-lbl">Tu formulario ha sido enviado!</div>
          <div className="instructions-lbl">Pronto nos comunicaremos contigo para coordinar la entrega de tu tarjeta.</div>
        </div>
      </PaymentConfirmationSection>
    </PurchaseLayout>
  )
}